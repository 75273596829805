<template>
<div>
  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Feedback Panel Config">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            
            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Title"
                  class=""
                >
                  <b-form-input
                    placeholder="2 to 50 characters"
                    v-model="form.title"
                    disabled
                  />
                </b-form-group>
              </b-col>

              
            </b-row>

            <b-row v-if="form.type == 'image'" class="mb-2">
              <b-col md="12">

                <b-form-group
                  label="Upload File (image, gif, video)"
                  class="mb-2 required">
                  <b-form-file
                    id="extension"
                    ref="file-input"
                    
                    accept=".png, .jpg, .jpeg, .gif, .mp4"
                    @change="fileUpdate"
                  />
                </b-form-group>
                
                <p class="mt-2">
                  <img :src="form.image.image" style="width:200px" v-if="file_type == 'image'"></img>
                  <video :src="form.image.image" style="width:200px" v-if="file_type == 'video'" :autoplay=false loop></video>
                </p>

              </b-col>
            </b-row>

            <b-row v-if="form.type == 'text'" class="mb-2">
              <b-col md="12">
                <b-form-group
                    :label="form.alias == 'master_pin' ? 'Master Pin' : (form.alias == 'desclaimer'? 'Desclaimer' : 'Seconds')"
                    class="required"
                  >
                    <b-form-input
                      placeholder=""
                      v-model="form.value"
                      autocomplete="off"
                    />
                </b-form-group>
              </b-col>
            </b-row>

            
            
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name:'feedback-panel-config' })"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';



export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    BFormFile,
    BBreadcrumb    
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,

      form :{
        id:'',
        title:'',
        alias:'',
        image: {
          image:null,
          name:''
        },
        type:'image',
        value:'',
        temp:'',
      },

      file_type:'image',
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/update-feedback-panel-config'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name:'feedback-panel-config' })
                });
                
            }
        });
      
    },

    fileUpdate(event){
      var input = event.target;
      var files = event.target.files
      //console.log(files);
      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          console.log(image.type);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg' && image.type != 'image/gif' && image.type != 'application/mp4' && image.type != 'video/mp4') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg, .gif images or mp4 video only',
                  showConfirmButton: false,
                  timer: 1500
                })
              
              this.$refs['file-input'].reset()

          } else {

              var type = image.type.split('/');
              
              this.file_type = type[1] == 'mp4' ? 'video' : 'image';

              reader.onload = (e) => {
                  this.form.image.image = e.target.result;
                  this.form.image.name = image.name;

                  //this.form.temp = '';
            }
          }
      }
    },
    
    getDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id
           },
           api: '/api/feedback-panel-config-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data = this.$store.getters.getResults.data;

                this.form.title   =   data.title;
                this.form.type    =   data.type;
                this.form.alias   =   data.alias;
                
                if (data.type == 'text') {
                  this.form.value   =   data.value;
                }else{
                  this.form.value   =   "";
                }

                if (data.type == 'image') {

                  this.form.image.image = data.image + '?'+ new Date();
                  this.form.image.name = '';

                  this.form.temp = data.image;


                  this.file_type =  data.image.split('.').pop() == 'mp4' ? 'video':'image';



                }else{
                  this.form.image.image = null;
                  this.form.image.name = '';
                  this.form.temp = null;                  
                }
                 
                this.form.id = data._id;
                return this.form;
            }
        });
    },
    
    breadCrumb(){
      var item = [{
        to:{name:'admin-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Feedback Panel',
      },{
        to:{name:'feedback-panel-config'},
        text: 'Others',
      },{
        to:{name:null},
        text:'Feedback Panel Config',
      }];
      return item;
    }   

  },
  mounted(){
    this.getDetail();
  }
}
</script>
